<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ Books Vendors</span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Books Vendors</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                            <label class="add-text">Add New Book Vendors</label>
                            <v-text-field class="text" v-model="currency" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                            <v-btn color="success" @click="addbooksVendors">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Books Vendors
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Books Vendors</label>
                                    <v-text-field class="text" v-model="booksvendorsName" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>
                    
                <template v-if="booksvendors_list!=null">
                    <v-data-table :headers="headers" :items="booksvendors_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updatecurrency(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteBooksVendors(item)"> mdi mdi-delete</v-icon>
                        </template>                      
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            overlay:false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            dialog: false,
            search: "",
            currency:null,
            booksvendorsId:null,
            booksvendors:null,
            booksvendorsName:null,
            userType: null,
            edit_btn_load: false,
            rules: {
                required: (value) => !!value || "Required.",
            },
            booksvendors_list: null,
            headers: [
                { text: "Sr no", value: "srno" },
                { text: "Name", value: "booksvendors" },
                { text: "Edit", value: "edit" },
                { text: "Delete", value: "delete" },
                // { text: "Toggle", value: "actions", sortable: false },
            ],
        }),
        watch: {},
        methods: {
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                console.log(item);
                axios
                    .post("/admin/currencyIsactive", item)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Book Vendors updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            updatecurrency(item) {
                this.dialog = true;
                this.booksvendorsName = item.booksvendors;
                this.booksvendorsId = item.booksvendorsId;
            },

            saveEdit(item) {
                if(this.booksvendorsName){
                this.booksvendorsName = this.booksvendorsName.trim()
                }
                console.log(item);
                const data = {
                    booksvendorsName: this.booksvendorsName,
                    booksvendorsId: this.booksvendorsId,
                };
                if (this.booksvendorsName) {
                    axios
                        .post("/admin/editBooksvendors", data)
                        .then((res) => {
                             if (res.data.msg == "success") {
                            this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Book Vendors updated successfully...");
                            this.onload();
                            this.dialog = false;
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                            this.edit_btn_load = false;
                            // this.editUserType = null;
                            axios;
                        });
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Books Vendors");
                }
            },
             deleteBooksVendors(item){                  
                const data = {            
                    booksvendors: item.booksvendors
                };
                axios
                    .post("/admin/deleteBooksVendors", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "Books Vendors Deleted Successfully  !!");
                            this.onload();
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Already in use!!!");
                        window.console.log(error);
                    });
            },

            onload() {
                this.overlay = true
                axios
                    .post("/admin/getBooksVendors")
                    .then((res) => {
                        this.org = res.data.org;
                        this.booksvendors_list = res.data.booksvendors;
                        console.log('this.booksvendors_list--',this.booksvendors_list);
                        this.overlay = false
                        //this.showSnackbar("#4CAF50","Success")
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

            addbooksVendors() {
                if(this.currency){
                this.currency = this.currency.trim()
                }
                const data = {
                    booksvendors_name: this.currency,
                };

                if (this.currency) {
                    axios
                        .post("/admin/addBooksVendors", data)
                        .then((res) => {
                            this.org = res.data.org;
                            this.booksvendors_list = res.data.booksvendors_list;
                            if (res.data.msg == "success") {
                                this.showSnackbar("#4CAF50", "Book Vendors added successfully...");
                                this.userType = null;
                                this.$refs.form.reset()
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {});
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Book Vendors");
                    this.$refs.form.validate()
                }
            },
        },
        mounted() {
            this.onload();
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
</style>
